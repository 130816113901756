@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;
// Variables

@include mat.core();

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

$bodyfont: 'Poppins', sans-serif;
$headingfont: 'Poppins', sans-serif;

/* For use in src/lib/core/theming/_palette.scss */
$mat-primary: (
    50 : #e8eaf6,
    100 : #c5cbe9,
    200 : #9fa8da,
    300 : #7985cb,
    400 : #5c6bc0,
    500 : #3f51b5,
    600 : #394aae,
    700 : #3140a5,
    800 : #29379d,
    900 : #1b278d,
    A100 : #c6cbff,
    A200 : #939dff,
    A400 : #606eff,
    A700 : #4757ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-secondary: (
    50 : #edebfb,
    100 : #d2ccf6,
    200 : #b4abf0,
    300 : #9689ea,
    400 : #806fe6,
    500 : #6956e1,
    600 : #614fdd,
    700 : #5645d9,
    800 : #4c3cd5,
    900 : #3b2bcd,
    A100 : #ffffff,
    A200 : #dddaff,
    A400 : #afa7ff,
    A700 : #978eff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-warn: (
    50 : #fce4ec,
    100 : #f8bcd0,
    200 : #f48fb1,
    300 : #f06292,
    400 : #ec407a,
    500 : #e91e63,
    600 : #e61a5b,
    700 : #e31651,
    800 : #df1247,
    900 : #d90a35,
    A100 : #ffffff,
    A200 : #ffd0d8,
    A400 : #ff9dae,
    A700 : #ff8498,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/*Material Theme Colors*/

$primary: mat.define-palette($mat-primary, 500);
$accent: mat.define-palette($mat-secondary, 500);
$warn: mat.define-palette($mat-warn, 500);


// $theme: mat-light-theme($primary, $accent, $warn);

// $background: map-get($theme, background);
// $foreground: map-get($theme, foreground);

$theme: mat.define-light-theme((
            color: (
                primary: $primary,
                accent: $accent,
                warn: $warn,
            ),
            typography:
            mat.define-typography-config(
                $font-family: "Poppins",
            ),
        )
);

@include mat.all-component-themes($theme);

@include mtx.all-component-themes($theme);

// Override the typography in the core CSS.
// @include mat-core($custom-typography);

// Default Theme
//@include angular-material-theme($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #28a745;
$warning: #ffb22b;
$primary: #1e88e5;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #f5ebde;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #28A74549;
$warning-dark: #e9ab2e;
$primary-dark: #0d4474;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

:root {
    --color-danger: #{$danger};
    --color-success: #{$success};
    --color-warning: #{$warning};
    --color-primary: #{$primary};
    --color-info: #{$info};
    --color-inverse: #{$inverse};
    --color-muted: #{$muted};
    --color-dark: #{$dark};
    --color-light: #{$light};
    --color-extra-light: #{$extra-light};
    --color-secondary: #{$secondary};

    --color-danger-dark: #e6294b;
    --color-success-dark: #{$success-dark};
    --color-warning-dark: #e9ab2e;
    --color-primary-dark: #{$primary-dark};
    --color-info-dark: #{$info-dark};
    --color-red-dark: #d61f1f;
    --color-inverse-dark: #232a37;

    --color-google: #dd4b39;
    --color-facebook: #3b5998;

    // Extra variables
    --color-dark-text: #{$dark-text};

    --color-light-danger: #{$light-danger};
    --color-light-success: #{$light-success};
    --color-light-warning: #{$light-warning};
    --color-light-primary: #{$light-primary};
    --color-light-info: #{$light-info};
    --color-light-inverse: #{$light-inverse};
    --color-light-megna: #{$light-megna};

}
