.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    &.dark-backdrop {
        background: #000;
        opacity: 0.85 !important;
    }
}

.button-group {
    display: flex;
    align-items: center;
    button~button {
        margin-left: 4px;
    }
}


small {
    font-size: 0.75em;
}

nb-form-field {
    padding: 5px;
}

.round-button {
    border-radius: 50% !important;
}

*[estado-convocatoria="PREPARADA"] {
    color: var(--color-info-dark);
}

*[estado-convocatoria="PUBLICADA"] {
    color: var(--color-info-dark);
}

*[estado-convocatoria="ACEPTADA"] {
    color: var(--color-success);
}

*[estado-convocatoria="CONFIRMADA"] {
    color: var(--color-success);
}

*[estado-convocatoria="RECHAZADA"] {
    color: var(--color-warning);
}

*[estado-convocatoria="CANCELADA"] {
    color: var(--color-danger);
}

*[estado-convocatoria="ELIMINADA"] {
    color: var(--color-danger-dark);
}

*[estado-convocatoria="FINALIZADA"] {
    color: var(--color-success-dark);
}

//PENDIENTE = 'PENDIENTE',
//ACEPTADA = 'ACEPTADA',
//RECHAZADA = 'RECHAZADA',
//CANCELADA = 'CANCELADA'

*[estado-sub="PENDIENTE"] {
    color: var(--color-info-dark);
}

*[estado-sub="ACEPTADA"] {
    color: var(--color-success);
}

*[estado-sub="RECHAZADA"] {
    color: var(--color-danger);
}

*[estado-sub="CANCELADA"] {
    color: var(--color-danger);
}

//    PREPARADA = 'PREPARADA',
//    PUBLICADA = 'PUBLICADA',
//    ACEPTADA = 'ACEPTADA',
//    CONFIRMADA = 'CONFIRMADA',
//    RECHAZADA = 'RECHAZADA',
//    CANCELADA = 'CANCELADA',
//    ELIMINADA = 'ELIMINADA',
//    FINALIZADA = 'FINALIZADA'

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.table-select {
    width: 100px !important;
}

.table-actions-cell {
    align-items: center;
    display: flex;
    flex-direction: row;
}

a {
    cursor: pointer;
}

p.text-line {
    margin-bottom: 0 !important;
    font-size: 0.8em;
    color: var(--color-dark-text);
}

.dialog-title-close {
    align-items: center;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}

.checkbox-standalone {
    mat-label ~ mat-checkbox {
        margin-left: 10px;
    }
    padding-right: 7px;
    padding-left: 7px;
}

.option-text--smaller {
    font-style: italic;
    font-size: 0.75em;
}


.top-header-card {
    margin-bottom: 15px;
    .mat-mdc-card-header {
        div.row {
            width: 100%;
            .mat-mdc-card-title {
                padding-left: 30px;
                align-items: center;
                display: flex;
            }
            .top-header-card-filter {
                align-items: center;
                display: flex;
                justify-content: flex-end;
                .mat-mdc-button {
                    margin-left: 10px;
                }
                .mat-mdc-form-field ~ .mat-mdc-form-field {
                    margin-left: 10px;
                }
            }
        }
    }
}

.filtro-familia {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid gray;

    .filtro-familia-select {
        width: 35%;
    }

    .filtro-texto {
        width: 35%;
        margin-right: 10px;
    }

    .filtro-checkbox {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 10px;
        width: 30%;
    }
}


.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.swiper-medium-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    .swiper-medium {
        height: 135px;
        flex-grow: 1;
    }
    .swiper-medium-arrow-right, .swiper-medium-arrow-left {
        cursor: pointer;
        flex-basis: 26px;
        align-self: center;
        justify-self: center;
    }
}

.error-text {
    font-weight: 600;
    color: var(--color-danger) !important;
}

.error-text-dark {
    font-weight: 600;
    color: var(--color-danger-dark) !important;
}

div[type="error"] {
    mat-icon {
        color: var(--color-danger);
    }
}
div[type="warn"] {
    mat-icon {
        color: var(--color-warning)
    }
}
div[type="info"] {
    mat-icon {
        color: var(--color-info);
    }
}

button.button-danger {
    background-color: var(--color-danger) !important;
    color: #ffffff;
}

button.button-warn {
    background-color: var(--color-warning) !important;
    color: #ffffff;
}

button.button-danger-dark {
    background-color: var(--color-danger-dark);
    color: #ffffff;
}

// Necesita ser global.
.panel-seguimiento-multiline-cell {
    display: flex;
    flex-direction: column;
}

.panel-seguimiento-multiline-small {
    display: flex;
    flex-direction: column;
    text-align: center;
    small {
        color: var(--color-dark-text);
        font-weight: 600;
    }
}

.tiny-column {
    min-width: 45px !important;;
}

.small-column {
    min-width: 75px !important;;
}

.medium-column {
    min-width: 100px !important;
}

.big-column {
    min-width: 130px !important;;
}

.danger-cell--bold {
    color: var(--color-danger) !important;
    font-weight: 600;
}

.footer-cell {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
    button ~ button {
        margin-left: 5px;
    }
    padding: 15px;
}

.link-primary {
    cursor: pointer;
}

// Data table classes.
.row-alarma-activa {
    background-color: var(--color-light-danger) !important;
}

.tooltip--pre-line {
    white-space: pre-line;
}

.mat-cell {
    app-alert-message {
        margin-left: 20px;
        margin-top: 20px;
    }
}

// Aplicar is-invalid de bootstrap a input de form control.
.form-control.ng-touched.ng-dirty.ng-invalid {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.small-icon {
    font-size: 15px !important;
    height: 15px !important;
    width: 15px !important;
}
.clickable {
    cursor: pointer;
}

.google {
    color: var(--color-google);
}

.custom-chip {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Esto hace que sea un círculo */
    text-align: center;
    overflow: hidden; /* Asegura que el contenido no salga del círculo */
    color: white; /* Color del texto */
    font-size: 12px; /* Tamaño del texto */
    &[color="primary"] {
        background-color: var(--color-primary);
    }
    &[color="warning"] {
        background-color: var(--color-warning);
    }
    &[color="danger"] {
        background-color: var(--color-danger);
    }
    &[color="success"] {
        background-color: var(--color-success);
    }
    width: 25px;
    height: 25px;
}

i.fa-icon-warn {
    color: var(--color-warning);
}

i.fa-icon-danger {
    color: var(--color-danger);
}

i.fa-icon-success {
    color: var(--color-success);
}

i.fa-icon-primary {
    color: var(--color-primary);
}


// Drag and drop

ngx-file-drop.upload-custom {
    height: 220px;
    display: flex;
}

.upload-custom {
    .ngx-file-drop__drop-zone {
        border: none !important;
    }
}

.upload-drag-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    mat-icon {
        $size: 48px;
        height: $size;
        width: $size;
        font-size: $size;
    }

    .upload-manual {
        a {
            font-weight: bold;
        }
    }
}


/* Anula el color del texto para los pasos inactivos y activos */
.inactive-stepper {
    .mat-step-header .mat-step-icon, .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit, .mat-step-header .mat-step-optional {
        background-color: var(--color-primary) !important; /* Usar el mismo color para el fondo y el texto para unificar */
        border-color: var(--color-primary) !important; /* Para el borde del icono, si es necesario */
    }
}


